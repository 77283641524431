<template>
  <v-app-bar color="white" white height="56" fixed app>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" @click="backPage">
      <circle cx="20" cy="20" r="19.5" stroke="#F3F3F3"/>
      <path d="M20.6569 14.3431L15 20L20.6569 25.6569" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <v-toolbar-title class="pl-0"></v-toolbar-title>
  </v-app-bar>
</template>

<script>


export default {
  name: "AppBar",
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
    backPage() {
      this.$router.go(-1)
      //    var main_path = this.$store.getters.getMainPath;
      //    this.$router.push(main_path);
    },

  },
}

</script>

<style lang="scss" scoped>
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
// mixin
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-app-bar {
  position: relative;
  width: 100%;
  height: 60px;
  margin: 0 auto;
  padding: 0 10px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;

  svg {
    cursor: pointer;
  }

  &::v-deep .v-toolbar__content {
    padding: 0;
  }
}
</style>